import React from 'react'
import Grid from '@material-ui/core/Grid'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { loginRequest } from '../../actions'

import AInput from '../01-atoms/a-input'
import { intlIdToString } from '../00-utils/u-translation'
import AButton from '../01-atoms/a-button'
import AForm from '../01-atoms/a-form'

class OLoginForm extends AForm {
  afterSubmit() {
    this.props.loginRequest({
      username: this.state.fields.emailValue.value,
      password: this.state.fields.pwValue.value,
    })
  }

  componentDidMount() {
    if (typeof this.props.data.login == 'object') navigate('/account') // Go to dashboard page
  }

  render() {
    const isSending = this.props.data.currentlySending

    return (
      <form
        onSubmit={this.handleSubmit}
        className={this.state.formValid ? '' : 'has-error'}
      >
        <Grid container spacing={3} justify="center">
          <Grid item xs={12} sm={10} md={6}>
            <AInput
              error={this.fieldHasError('emailValue')}
              label={intlIdToString('email')}
              name="emailValue"
              onChange={this.handleChange.bind(this, ['isValidMail'])}
              onEnter={name => this.fieldRegister(name)}
              required
              type="email"
              value={
                this.state.fields.emailValue !== undefined
                  ? this.state.fields.emailValue.value
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} sm={10} md={6}>
            <AInput
              error={this.fieldHasError('pwValue')}
              label={intlIdToString('password')}
              name="pwValue"
              onChange={this.handleChange.bind(this, ['isValidPassword'])}
              onEnter={name => this.fieldRegister(name)}
              required
              type="password"
              value={
                this.state.fields.pwValue !== undefined
                  ? this.state.fields.pwValue.value
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} sm={10} md={12}>
            <AButton
              variant="contained"
              type="submit"
              disabled={isSending || !this.state.formValid}
              loading={isSending}
            >
              {intlIdToString('login')}
            </AButton>
          </Grid>
        </Grid>
      </form>
    )
  }
}

OLoginForm.propTypes = {
  data: PropTypes.object.isRequired,
  loginRequest: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  return {
    data: {
      login: state.login,
      currentlySending: state.currentlySending,
    },
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loginRequest: data => dispatch(loginRequest(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OLoginForm)
