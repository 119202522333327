import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/styles/withStyles'
import SimpleTemplateWrapper from '../components/04-templates/t-simple-template-wrapper'

import { intlIdToString } from '../components/00-utils/u-translation'

import ALink from '../components/01-atoms/a-link.js'
import APaper from '../components/01-atoms/a-paper'
import OLoginForm from '../components/03-organisms/o-login-form.js'

const styles = theme => ({
  resetButton: {
    float: 'right',
    paddingRight: 0,
    marginTop: theme.typography.pxToRem(-30),
  },
})

class LoginPage extends Component {
  render() {
    const classes = this.props.classes
    return (
      <SimpleTemplateWrapper showHeadlineBar headline={intlIdToString('login')}>
        <APaper>
          <OLoginForm />
          <ALink to="/password/reset" className={classes.resetButton}>
            <FormattedMessage id="forgotPassword" />
          </ALink>
        </APaper>
      </SimpleTemplateWrapper>
    )
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(LoginPage)
